import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"

class CategoryCanger extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    const children = document.getElementById("links").children
    if(e.target.value == ""){
      for (let i = 0; i < children.length; i++) {
        children[i].removeAttribute("hidden")
      }
      return
    }
    // return document.location.href= "#" + e.target.value
    for (let i = 0; i < children.length; i++) {
      children[i].setAttribute("hidden", true)
    }
    document.getElementById(e.target.value).removeAttribute("hidden")
  }

  render() {
      const selected = this.selected
      return (
        <p>
          <select onChange={this.handleChange}>
            <option value="">カテゴリーでフィルタ</option>
            {Object.keys(this.props.categories).map(( category ) => {
              return (
                <option value={ category }>{ category }</option>
              )
            })}
          </select>
        </p>
      )
  }
}


const SitemapIndex = ({ data, location }) => {
  const siteTitle = "サイトマップ"
  const posts = data.allMarkdownRemark.edges

  let categories = []

  posts.map(( { node } ) => {
    const title = node.frontmatter.title || node.fields.slug
    const cats = node.frontmatter.tags
    if (!Array.isArray(cats)) return
    cats.map(( cat ) => {
      if(!Array.isArray(categories[cat])){
        categories[cat] = []
      }
      categories[cat].push({"title": title, "link": node.fields.slug})
    })
  })

  return (
    <Layout location={location} title={siteTitle}>
      <SEO location={location} title={siteTitle} />
      <Bio />
      <h3>固定ページ</h3>
      <p>
        <Link to="/">ブログ一覧</Link>
      </p>
      <p>
        <Link to="/about/">このブログについて</Link>
      </p>
      <p>
        <Link to="/sitemap/">サイトマップ</Link>
      </p>
      <CategoryCanger categories={categories}></CategoryCanger>
      <div id="links">{Object.keys(categories).map(( category ) => {
        return (
          <div class="categories" id={ category }>
            <h3>カテゴリー：{ category }</h3>
            {categories[category].map(( node ) => {
              const title = node.title || node.slug
              return (
                <p>
                  <Link to={node.link}>
                    {title}
                  </Link>
                </p>
              )
            })}
          </div>
        )
      })}</div>
    </Layout>
  )
}

export default SitemapIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            tags
          }
        }
      }
    }
  }
`
